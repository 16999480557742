<template>
  <page-layout>
    <v-col cols="12" class="accruals-table">
      <simple-card-with-tabs :items="tabItems">
        <template v-slot:title-left>Начисления, платежи, ЕПД</template>
        <template v-slot:title-right>
          <v-btn color="primary" @click="goToPaymentPage" class="mr-1"
            >Оплатить</v-btn
          >
          <v-btn
            color="secondary"
            @click="accrualsService.downloadReceipt"
            :loading="needLoadingIndicator"
          >
            Квитанция <v-icon>mdi-download</v-icon>
          </v-btn>
        </template>
      </simple-card-with-tabs>
    </v-col>
  </page-layout>
</template>

<script>
import SimpleCardWithTabs from "../../components/cards/SimpleCardWithTabs";
import PageLayout from "../../components/Layouts/PageLayout";
import { mapGetters } from "vuex";
import AccrualsService from "../../services/AccrualsService";
import loadingIndicator from "../../shared/mixins/loadingIndicator";

export default {
  name: "Accruals",
  components: { SimpleCardWithTabs, PageLayout },
  mixins: [loadingIndicator],
  data() {
    return {
      loadingFlag: "get_epd",
      accrualsService: AccrualsService,
    };
  },
  computed: {
    ...mapGetters("account", ["lastChargesByService", "chargeItems"]),
    detailsRows() {
      return this.accrualsService.getDetailsRows(this.lastChargesByService);
    },
    historyRows() {
      return this.accrualsService.getHistoryRows(this.chargeItems);
    },
    tabItems() {
      return this.accrualsService.getTabItems(
        this.detailsRows,
        this.historyRows
      );
    },
  },
  methods: {
    goToPaymentPage() {
      this.$router.push({ name: "payment" });
    },
  },
};
</script>

<style>
/* scoped */
.accruals-table td.v-data-table__mobile-row {
  max-width: calc(100vw - 30px) !important;
}
.accruals-table .v-data-table__mobile-row__header {
  text-align: left;
}
</style>
