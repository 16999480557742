<template>
  <simple-card>
    <template v-slot:title-left><slot name="title-left"></slot></template>
    <template v-slot:title-right><slot name="title-right"></slot></template>
    <template v-slot:content>
      <v-tabs class="mb-4" v-model="tab">
        <v-tab v-for="item in items" :key="item.tab">
          {{ item.tab }}
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab">
        <v-tab-item v-for="item in items" :key="item.tab">
          <component v-bind="item.props" :is="item.component"></component>
        </v-tab-item>
      </v-tabs-items>
    </template>
    <template v-slot:footer></template>
  </simple-card>
</template>

<script>
import SimpleCard from "./SimpleCard";

export default {
  name: "SimpleCardWithTabs",
  components: { SimpleCard },
  props: {
    items: Array,
  },
  data() {
    return {
      tab: 0,
    };
  },
};
</script>

<style scoped></style>
